import { DateTime } from "luxon";

import type { CalendarDate, Locale, LocalTime } from "./common";

// Even though Node.js uses CLDR, which has all the time zone abbreviations, only a subset of the abbreviations
// is available for each locale. None of the locales have every abbreviation.
// This means that we cannot use DateTime's offsetNameShort or format token 'ZZZZ', because certain time zones
// will fall back to GMT+X. Instead, we use offsetNameLong and use the mapping below.
const timeZoneLongToShortMapping: { [long: string]: string } = {
  "Australian Central Daylight Time": "ACDT",
  "Australian Central Standard Time": "ACST",
  "Acre Time": "ACT",
  "Australian Central Time": "ACT",
  "Australian Central Western Standard Time": "ACWST",
  "Arabia Daylight Time": "ADT",
  "Atlantic Daylight Time": "ADT",
  "Australian Eastern Daylight Time": "AEDT",
  "Australian Eastern Standard Time": "AEST",
  "Australian Eastern Time": "AET",
  "Afghanistan Time": "AFT",
  "Alaska Daylight Time": "AKDT",
  "Alaska Standard Time": "AKST",
  "Alma-Ata Time": "ALMT",
  "Amazon Summer Time": "AMST",
  "Armenia Summer Time": "AMST",
  "Amazon Time": "AMT",
  "Armenia Time": "AMT",
  "Anadyr Summer Time": "ANAST",
  "Anadyr Time": "ANAT",
  "Aqtobe Time": "AQTT",
  "Argentina Time": "ART",
  "Arabia Standard Time": "AST",
  "Atlantic Standard Time": "AST",
  "Atlantic Time": "AT",
  "Australian Western Daylight Time": "AWDT",
  "Australian Western Standard Time": "AWST",
  "Azores Summer Time": "AZOST",
  "Azores Time": "AZOT",
  "Azerbaijan Summer Time": "AZST",
  "Azerbaijan Time": "AZT",
  "Anywhere on Earth": "AoE",
  "Brunei Darussalam Time": "BNT",
  "Bolivia Time": "BOT",
  "Brasília Summer Time": "BRST",
  "Brasília Time": "BRT",
  "Bangladesh Standard Time": "BST",
  "Bougainville Standard Time": "BST",
  "British Summer Time": "BST",
  "Bhutan Time": "BTT",
  "Casey Time": "CAST",
  "Central Africa Time": "CAT",
  "Cocos Islands Time": "CCT",
  "Central Daylight Time": "CDT",
  "Cuba Daylight Time": "CDT",
  "Central European Standard Time": "CET",
  "Central European Summer Time": "CEST",
  "Central European Time": "CET",
  "Chatham Island Daylight Time": "CHADT",
  "Chatham Island Standard Time": "CHAST",
  "Choibalsan Summer Time": "CHOST",
  "Choibalsan Time": "CHOT",
  "Chuuk Time": "CHUT",
  "Cayman Islands Daylight Saving Time": "CIDST",
  "Cayman Islands Standard Time": "CIST",
  "Cook Island Time": "CKT",
  "Chile Summer Time": "CLST",
  "Chile Standard Time": "CLT",
  "Colombia Time": "COT",
  "Central Standard Time": "CST",
  "China Standard Time": "CST",
  "Cuba Standard Time": "CST",
  "Central Time": "CT",
  "Cape Verde Time": "CVT",
  "Christmas Island Time": "CXT",
  "Chamorro Standard Time": "ChST",
  "Davis Time": "DAVT",
  "Dumont-d'Urville Time": "DDUT",
  "Easter Island Summer Time": "EASST",
  "Easter Island Standard Time": "EAST",
  "Eastern Africa Time": "EAT",
  "Ecuador Time": "ECT",
  "Eastern Daylight Time": "EDT",
  "Eastern European Summer Time": "EEST",
  "Eastern European Time": "EET",
  "Eastern Greenland Summer Time": "EGST",
  "East Greenland Time": "EGT",
  "Eastern Standard Time": "EST",
  "Eastern Time": "ET",
  "Further-Eastern European Time": "FET",
  "Fiji Summer Time": "FJST",
  "Fiji Time": "FJT",
  "Falkland Islands Summer Time": "FKST",
  "Falkland Island Time": "FKT",
  "Fernando de Noronha Time": "FNT",
  "Galapagos Time": "GALT",
  "Gambier Time": "GAMT",
  "Georgia Standard Time": "GET",
  "French Guiana Time": "GFT",
  "Gilbert Island Time": "GILT",
  "Greenwich Mean Time": "GMT",
  "Gulf Standard Time": "GST",
  "South Georgia Time": "GST",
  "Guyana Time": "GYT",
  "Hawaii-Aleutian Daylight Time": "HDT",
  "Hong Kong Time": "HKT",
  "Hovd Summer Time": "HOVST",
  "Hovd Time": "HOVT",
  "Hawaii Standard Time": "HST",
  "Indochina Time": "ICT",
  "Israel Daylight Time": "IDT",
  "Indian Chagos Time": "IOT",
  "Iran Daylight Time": "IRDT",
  "Irkutsk Summer Time": "IRKST",
  "Irkutsk Time": "IRKT",
  "Iran Standard Time": "IRST",
  "India Standard Time": "IST",
  "Irish Standard Time": "IST",
  "Israel Standard Time": "IST",
  "Japan Standard Time": "JST",
  "Kyrgyzstan Time": "KGT",
  "Kosrae Time": "KOST",
  "Krasnoyarsk Summer Time": "KRAST",
  "Krasnoyarsk Time": "KRAT",
  "Korea Standard Time": "KST",
  "Kuybyshev Time": "KUYT",
  "Lord Howe Daylight Time": "LHDT",
  "Lord Howe Standard Time": "LHST",
  "Line Islands Time": "LINT",
  "Magadan Summer Time": "MAGST",
  "Magadan Time": "MAGT",
  "Marquesas Time": "MART",
  "Mawson Time": "MAWT",
  "Mountain Daylight Time": "MDT",
  "Marshall Islands Time": "MHT",
  "Myanmar Time": "MMT",
  "Moscow Daylight Time": "MSD",
  "Moscow Standard Time": "MSK",
  "Mountain Standard Time": "MST",
  "Mountain Time": "MT",
  "Mauritius Time": "MUT",
  "Maldives Time": "MVT",
  "Malaysia Time": "MYT",
  "New Caledonia Time": "NCT",
  "Newfoundland Daylight Time": "NDT",
  "Norfolk Daylight Time": "NFDT",
  "Norfolk Time": "NFT",
  "Novosibirsk Summer Time": "NOVST",
  "Novosibirsk Time": "NOVT",
  "Nepal Time": "NPT",
  "Nauru Time": "NRT",
  "Newfoundland Standard Time": "NST",
  "Niue Time": "NUT",
  "New Zealand Daylight Time": "NZDT",
  "New Zealand Standard Time": "NZST",
  "Omsk Summer Time": "OMSST",
  "Omsk Standard Time": "OMST",
  "Oral Time": "ORAT",
  "Pacific Daylight Time": "PDT",
  "Peru Time": "PET",
  "Kamchatka Summer Time": "PETST",
  "Kamchatka Time": "PETT",
  "Papua New Guinea Time": "PGT",
  "Phoenix Island Time": "PHOT",
  "Philippine Time": "PHT",
  "Pakistan Standard Time": "PKT",
  "Pierre & Miquelon Daylight Time": "PMDT",
  "Pierre & Miquelon Standard Time": "PMST",
  "Pohnpei Standard Time": "PONT",
  "Pacific Standard Time": "PST",
  "Pitcairn Standard Time": "PST",
  "Pacific Time": "PT",
  "Palau Time": "PWT",
  "Paraguay Summer Time": "PYST",
  "Paraguay Time": "PYT",
  "Pyongyang Time": "PYT",
  "Qyzylorda Time": "QYZT",
  "Reunion Time": "RET",
  "Rothera Time": "ROTT",
  "Sakhalin Time": "SAKT",
  "Samara Time": "SAMT",
  "South Africa Standard Time": "SAST",
  "Solomon Islands Time": "SBT",
  "Seychelles Time": "SCT",
  "Singapore Time": "SGT",
  "Srednekolymsk Time": "SRET",
  "Suriname Time": "SRT",
  "Samoa Standard Time": "SST",
  "Syowa Time": "SYOT",
  "Tahiti Time": "TAHT",
  "French Southern and Antarctic Time": "TFT",
  "Tajikistan Time": "TJT",
  "Tokelau Time": "TKT",
  "East Timor Time": "TLT",
  "Turkmenistan Time": "TMT",
  "Tonga Summer Time": "TOST",
  "Tonga Time": "TOT",
  "Turkey Time": "TRT",
  "Tuvalu Time": "TVT",
  "Ulaanbaatar Summer Time": "ULAST",
  "Ulaanbaatar Time": "ULAT",
  "Coordinated Universal Time": "UTC",
  "Uruguay Summer Time": "UYST",
  "Uruguay Time": "UYT",
  "Uzbekistan Time": "UZT",
  "Venezuelan Standard Time": "VET",
  "Vladivostok Summer Time": "VLAST",
  "Vladivostok Time": "VLAT",
  "Vostok Time": "VOST",
  "Vanuatu Time": "VUT",
  "Wake Time": "WAKT",
  "Western Argentine Summer Time": "WARST",
  "West Africa Summer Time": "WAST",
  "West Africa Time": "WAT",
  "Western European Summer Time": "WEST",
  "Western European Time": "WET",
  "Wallis and Futuna Time": "WFT",
  "Western Greenland Summer Time": "WGST",
  "West Greenland Time": "WGT",
  "Western Indonesian Time": "WIB",
  "Eastern Indonesian Time": "WIT",
  "Central Indonesian Time": "WITA",
  "West Samoa Time": "WST",
  "Western Sahara Summer Time": "WST",
  "Western Sahara Standard Time": "WT",
  "Yakutsk Summer Time": "YAKST",
  "Yakutsk Time": "YAKT",
  "Yap Time": "YAPT",
  "Yekaterinburg Summer Time": "YEKST",
  "Yekaterinburg Time": "YEKT",
};

/**
 * Date formatter, uses Luxon but fixes its ZZZZ token (abbreviated offset name).
 */
export const formatDateTime = (
  date: Date,
  format: string,
  timeZone?: string,
  locale?: Locale,
): string => {
  const dateTime = DateTime.fromJSDate(date, {
    ...(timeZone && { zone: timeZone }),
    ...(locale && { locale }),
  });
  const parts = format.split(/\bZZZZ\b/);
  if (parts.length <= 1) return dateTime.toFormat(format);

  const timeZoneLong = dateTime.offsetNameLong || "";
  const timeZoneShort = dateTime.offsetNameShort?.startsWith("GMT")
    ? timeZoneLongToShortMapping[timeZoneLong] ??
      (timeZoneLong.replace(/[^A-Z]/g, "") || dateTime.offsetNameShort)
    : dateTime.offsetNameShort;

  return parts
    .map(
      (part, index) =>
        dateTime.toFormat(part) +
        (index < parts.length - 1 ? timeZoneShort : ""),
    )
    .join("");
};

export const formatLocalTime = (
  time: LocalTime,
  format: string,
  locale?: Locale,
): string => formatDateTime(new Date(time.date), format, time.timeZone, locale);

export const getDateTimeFromLocalTime = (time: LocalTime, locale?: Locale) =>
  DateTime.fromISO(time.date, {
    ...(time.timeZone && { zone: time.timeZone }),
    ...(locale && { locale }),
  });

const splitCalendarDate = (date: CalendarDate) =>
  date.split("-").map((n) => Number.parseInt(n, 10)) as [
    number,
    number,
    number,
  ];

export const formatCalendarDate = (
  date: CalendarDate,
  format: string,
  locale?: Locale,
) => {
  const splitDate = splitCalendarDate(date);
  splitDate[1] -= 1;
  return formatDateTime(
    new Date(Date.UTC(...splitDate)),
    format,
    "UTC",
    locale,
  );
};

function capitalize(word: string | undefined) {
  return word
    ? word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase()
    : "";
}

const isValidLocale = (locale: string) => {
  try {
    // eslint-disable-next-line no-new
    new Intl.DateTimeFormat(locale);
    return true;
  } catch {
    return false;
  }
};

const parseCalendarDate = (date: CalendarDate, locale: Locale = "en-US") => {
  if (!locale || !isValidLocale(locale)) {
    // eslint-disable-next-line no-param-reassign
    locale = "en-US";
  }

  const dateTime = DateTime.fromISO(date, { locale });
  return dateTime.isValid
    ? {
        year: dateTime.toFormat("yyyy"),
        month: capitalize(dateTime.toFormat("MMMM")),
        day: dateTime.toFormat("dd"),
        dayOfWeek: capitalize(dateTime.toFormat("EEEE")),
      }
    : undefined;
};

export const formatDateRange = (
  startDate: CalendarDate,
  endDate: CalendarDate,
  locale?: Locale,
) => {
  const start = parseCalendarDate(startDate, locale);
  const end = parseCalendarDate(endDate, locale);

  if (!start || !end) {
    return undefined;
  }

  const startComponents: string[] = [];
  const endComponents: string[] = [];
  const sharedComponents: string[] = [];

  start.dayOfWeek = `${start.dayOfWeek},`;
  end.dayOfWeek = `${end.dayOfWeek},`;

  start.year = `, ${start.year}`;
  end.year = `, ${end.year}`;

  if (locale === "en-US") {
    const originalStartMonth = start.month;
    const originalEndMonth = end.month;
    start.month = start.day;
    start.day = originalStartMonth;
    end.month = end.day;
    end.day = originalEndMonth;
  }

  if (start.year === end.year) {
    sharedComponents.unshift(start.year);
    if (start.month === end.month) {
      sharedComponents.unshift(start.month);
      if (start.day === end.day) {
        sharedComponents.unshift(start.day);
        sharedComponents.unshift(start.dayOfWeek);
      } else {
        startComponents.unshift(start.day);
        startComponents.unshift(start.dayOfWeek);
        endComponents.unshift(end.day);
        endComponents.unshift(end.dayOfWeek);
      }
    } else {
      startComponents.unshift(start.dayOfWeek, start.day, start.month);
      endComponents.unshift(end.dayOfWeek, end.day, end.month);
    }
  } else {
    startComponents.unshift(
      start.dayOfWeek,
      start.day,
      start.month,
      start.year,
    );
    endComponents.unshift(end.dayOfWeek, end.day, end.month, end.year);
  }

  const output =
    startComponents.length === 0
      ? sharedComponents.join(" ")
      : `${startComponents.join(" ")}\u2009\u2013\u2009${[
          ...endComponents,
          ...sharedComponents,
        ].join(" ")}`;

  return output.replace(/ ,/g, ",");
};

const millisecondsPerDay = 1000 * 60 * 60 * 24;

export const getNumberOfNights = (
  startDate: CalendarDate,
  endDate: CalendarDate,
) => {
  const splitStartDate = splitCalendarDate(startDate);
  const splitEndDate = splitCalendarDate(endDate);
  splitStartDate[1] -= 1;
  splitEndDate[1] -= 1;
  return Math.floor(
    (Date.UTC(...splitEndDate) - Date.UTC(...splitStartDate)) /
      millisecondsPerDay,
  );
};

export const getNumberOfDays = (
  startDate: CalendarDate,
  endDate: CalendarDate,
) => getNumberOfNights(startDate, endDate) + 1;
